import { createApp } from 'vue';
import router from './routes';
import App from './components/layout/App.vue';
import axios from 'axios';
import i18n from '@shared/i18n';
import appLibrary from '@shared/base/appLibrary';
import * as Sentry from '@sentry/vue';
import platformDataHydrator from '@shared/services/ipsevents';
import { IpsEvents } from '@iphoneps/events';
import { sentryErrorTracking } from '@shared/sentry';
import { ApiClient } from '@shared/api/api';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash, faChevronLeft, faAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons';

import '../sass/login.scss';

const app = createApp(App);

// Add component name to Sentry tags
app.config.errorHandler = (err, vm) => {
  Sentry.setTag('component', vm.$.type.name);
};

/* SENTRY */
sentryErrorTracking(app, router);

axios.defaults.headers.common = {
  'x-lang': window.App.language ?? 'en',
  Accept: 'application/json, text/plain, */*',
};

/* Global properties */
app.config.globalProperties.$http = axios;
app.config.globalProperties.$api = new ApiClient();

/* Set up router */
function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}
// Persists url query params
router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }
});
app.use(router);

/* i18n */
app.use(i18n);

// IPS Events
const ipse = new IpsEvents(platformDataHydrator, 'https://events.iphonephotographyschool.com/v1/collect', Sentry);
window.ipse = ipse;

/* Global mixins */
app.mixin(appLibrary);

/* FONT AWESOME */
app.component('font-awesome-icon', FontAwesomeIcon);
library.add(faEye, faEyeSlash, faChevronLeft, faAngleDoubleRight);

/* Mount app when navigation is ready */
router.isReady().then(() => app.mount('#app'));
