<template>
  <div class="form-view">
    <div class="form-view__content">
      <div class="form-view__nest">
        <div class="form-view__inner">
          <div class="form-view__top">
            <router-link :to="{ name: 'login' }" class="form-view__back">
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </router-link>

            <logo></logo>
          </div>

          <h1 class="form-view__title">{{ $t('auth.createNewPassword') }}</h1>
          <p class="form-view__description" v-html="$t('auth.createYourNewPassword')"></p>

          <div class="form-view__form">
            <fieldset class="form-view__fieldset">
              <div
                class="form-view__input-nest form-view__input-nest--password"
                :class="{ 'form-view__input-nest--error': !noErrors && !isMessage }">
                <input
                  v-model.trim="form.password"
                  :type="passwordVisible ? 'text' : 'password'"
                  name="password"
                  placeholder=" "
                  id="password"
                  class="form-view__input form-view__input--password"
                  :class="{ 'form-view__input--error': !noErrors && !isMessage }"
                  @input="clearErrors"
                  @keyup.enter="submitForm"
                  autocapitalize="off" />
                <div class="form-view__password-icon" title="Show Password" @click.stop="togglePasswordVisible">
                  <font-awesome-icon :icon="['fas', 'eye-slash']" v-if="passwordVisible" />
                  <font-awesome-icon :icon="['fas', 'eye']" v-else />
                </div>

                <label for="password" class="form-view__placeholder">
                  {{ noErrors || !canSubmit ? $t('auth.enterYourNewPassword') : $t('auth.enterANewPassword') }}
                </label>
              </div>
            </fieldset>

            <div class="form-view__errors" v-if="!noErrors && canSubmit && !isMessage">
              <p class="form-view__errors-msg">{{ $t('auth.pleaseChooseMoreSecurePassword') }}</p>

              <ul class="form-view__errors-list">
                <li v-for="error in errors.password" :key="error">
                  {{ error }}
                </li>
              </ul>
            </div>

            <button
              class="form-view__submit"
              :class="{ 'form-view__submit--disabled': !canSubmit }"
              @click="submitForm">
              <span class="form-view__submit-loading" v-if="submitLoading"></span>
              <template v-else>{{ $t('auth.resetPassword') }}</template>
            </button>
          </div>

          <div class="form-view__bottom">
            <router-link :to="{ name: 'contact' }" @click="trackNavigationToContact">{{
              $t('forms.needHelp')
            }}</router-link>
          </div>
        </div>
      </div>

      <language-switcher :floating="true" />
    </div>

    <sidebar :backgroundRandom="sidebarRightImg"></sidebar>
  </div>
</template>

<script>
import Sidebar from '@login/components/layout/Sidebar.vue';
import Logo from '@login/components/layout/Logo.vue';
import LanguageSwitcher from '@shared/components/ui/LanguageSwitcher.vue';
import loginMixin from '@login/mixins/loginMixin';
import appClient from '@shared/api/appClient';

export default {
  name: 'ResetPasswordForm',
  components: {
    Sidebar,
    Logo,
    LanguageSwitcher,
  },
  props: {
    sidebarRightImg: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      passwordVisible: false,
      submitLoading: false,
    };
  },
  computed: {
    canSubmit() {
      return this.form.password.length;
    },
  },
  mixins: [loginMixin],
  methods: {
    submitForm() {
      this.validateNewPassword();

      if (!this.noErrors || this.submitLoading) {
        return;
      }

      const form = new FormData();

      form.append('token', this.$route.query.token);
      form.append('email', this.$route.query.email);
      form.append('password', this.form.password);
      form.append('password_confirmation', this.form.password);

      this.submitLoading = true;

      appClient
        .resetPassword(form)
        .then(() => {
          this.submitLoading = false;
          window.location = '/';
        })
        .catch(({ response }) => {
          this.submitLoading = false;
          this.$root.errors = response.data.errors ? response.data.errors : {};
        });
    },
    togglePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
    trackNavigationToContact() {
      this.trackEvent('button_clicked', { button: 'create_password_view_need_help' });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-view {
  &__submit-loading {
    border-top-color: var(--black);
  }
}
</style>
