<template>
  <div class="form-view">
    <div class="form-view__content">
      <div class="form-view__nest">
        <div class="form-view__inner">
          <div class="form-view__top">
            <router-link :to="{ name: 'forgot-password' }" class="form-view__back">
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </router-link>

            <logo></logo>
          </div>

          <h1 class="form-view__title">{{ $t('forms.checkYourEmail') }}</h1>
          <p class="form-view__description" v-html="$t('auth.sentEmailResetLink')"></p>

          <hr class="check-your-email__sep" />

          <div class="check-your-email__list">
            <ol>
              <li>{{ $t('auth.logInToYourEmail') }}</li>
              <li>
                {{ $t('auth.openTheEmail') }} {{ domain === 'ips' ? 'iPhone Photography School' : 'Photography Pro' }}
                {{ $t('auth.password') }}
              </li>
              <li>{{ $t('auth.clickResetLink') }}</li>
            </ol>
          </div>
        </div>
      </div>

      <language-switcher :floating="true" />
    </div>

    <sidebar :backgroundRandom="sidebarRightImg"></sidebar>
  </div>
</template>

<script>
import Sidebar from '@login/components/layout/Sidebar.vue';
import Logo from '@login/components/layout/Logo.vue';
import LanguageSwitcher from '@shared/components/ui/LanguageSwitcher.vue';
import loginMixin from '@login/mixins/loginMixin';

export default {
  name: 'CheckYourEmail',
  components: {
    Sidebar,
    Logo,
    LanguageSwitcher,
  },
  props: {
    sidebarRightImg: {
      type: Number,
      required: true,
    },
  },
  mixins: [loginMixin],
  // Redirect to login screen if URL accessed directly
  beforeRouteEnter(to, from, next) {
    if (from.name === 'forgot-password') {
      next();
    } else {
      window.location = '/login';
    }
  },
};
</script>

<style lang="scss" scoped>
.check-your-email {
  &__list {
    font-size: $text-lg;
    text-align: left;
    color: var(--gray-3);
    padding: 0 20px;

    @include tablet-portrait {
      padding: 0 30px;
    }
    @include iphone-11(landscape) {
      padding: 0;
    }
    @include iphone-11-pro-max(landscape) {
      padding: 0;
    }
    @include iphone-12-pro-max(landscape) {
      padding: 0;
    }

    ol {
      padding: 0;
      margin-left: 20px;
    }

    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &::marker {
        color: var(--white);
      }
    }
  }

  &__sep {
    margin: 10px 0;
    border: none;
    border-radius: 2px;
    border-bottom: 2px solid var(--gray-5);

    @include phone-landscape(landscape) {
      margin: 0 0 10px 0;
    }
    @include tablet-portrait {
      margin: 0 0 10px 0;
    }
  }
}
</style>
