<template>
  <main class="login">
    <app-mode-listeners v-if="isAppMode" />
    <router-view v-slot="{ Component, route }">
      <transition :name="route.meta.transition">
        <component
          ref="childComponent"
          :form="form"
          :is="Component"
          :stepPrevious="stepPrevious"
          :sidebarLeftImg="sidebarLeftImg"
          :sidebarRightImg="sidebarRightImg"
          :customerPopupClosed="customerPopupClosed"
          @changeStep="changeStep"
          @showPopup="isMessageSent = true"
          @showNewUser="customerPopupClosed = false"
          @showRecaptcha="isRecaptcha = true" />
      </transition>
    </router-view>

    <transition name="scale">
      <popup v-if="isMessageSent" @close="isMessageSent = false">
        <template v-slot:heading>
          {{ $t('contact.messageSent') }}
        </template>
        <template v-slot:description v-html="$t('contact.thanks')"></template>
      </popup>
    </transition>

    <transition name="fade-in">
      <recaptcha @recaptchaSuccess="recaptchaSuccess" v-show="isRecaptcha"></recaptcha>
    </transition>
  </main>
</template>

<script>
import Popup from '@login/components/ui/Popup.vue';
import Recaptcha from '@login/components/ui/Recaptcha.vue';
import { iosview } from '@course-portal/iosview.js';
import AppModeListeners from '@course-portal/components/listeners/AppModeListeners.vue';

export default {
  name: 'AppLogin',
  components: {
    Popup,
    Recaptcha,
    AppModeListeners,
  },
  data() {
    return {
      stepPrevious: null,
      customerPopupClosed: true,
      isMessageSent: false,
      isRecaptcha: false,
      form: {
        firstName: '',
        email: '',
        password: '',
        message: '',
      },
      errors: {},
      message: null,
    };
  },
  computed: {
    sidebarLeftImg() {
      const max = this.domain === 'ips' ? 10 : 7;
      return Math.floor(Math.random() * max) + 1;
    },
    sidebarRightImg() {
      const num = this.generateRandom();
      return num === this.sidebarLeftImg ? this.generateRandom() : num;
    },
  },
  methods: {
    changeStep() {
      this.stepPrevious = val.stepPrevious;
    },
    // iPad 100% height without scroll hack
    getAppHeight() {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    },
    iPadHeightFix() {
      window.addEventListener('resize', this.getAppHeight);
      this.getAppHeight();
    },
    preloadSidebarImage() {
      const imageLeft = new Image();
      const imageRight = new Image();

      imageLeft.src = this.vaporAsset(`images/login/${this.domain}_login_image_${this.sidebarLeftImg}.jpg`);

      imageLeft.srcset = `
        ${this.vaporAsset(`images/login/${this.domain}_login_image_${this.sidebarLeftImg}@3x.jpg 3x`)},
        ${this.vaporAsset(`images/login/${this.domain}_login_image_${this.sidebarLeftImg}@2x.jpg 2x`)},
        ${this.vaporAsset(`images/login/${this.domain}_login_image_${this.sidebarLeftImg}.jpg 1x`)}
      `;

      imageRight.src = this.vaporAsset(`images/login/${this.domain}_login_image_${this.sidebarRightImg}.jpg`);

      imageRight.srcset = `
        ${this.vaporAsset(`images/login/${this.domain}_login_image_${this.sidebarRightImg}@3x.jpg 3x`)},
        ${this.vaporAsset(`images/login/${this.domain}_login_image_${this.sidebarRightImg}@2x.jpg 2x`)},
        ${this.vaporAsset(`images/login/${this.domain}_login_image_${this.sidebarRightImg}.jpg 1x`)}
      `;
    },
    generateRandom() {
      return Math.floor(Math.random() * 5) + 1;
    },
    populateQueryData() {
      if (this.$route.query.email) {
        this.form.email = this.$route.query.email;
      }
    },
    recaptchaSuccess(token) {
      this.form.recaptcha_token = token;
      this.isRecaptcha = false;
      // Reset email field error state
      delete this.$refs.childComponent.errors.email;
    },
  },
  mounted() {
    if (this.isAppMode) {
      this.disableZoom();
    }
    this.iPadHeightFix();
    this.preloadSidebarImage();
    this.populateQueryData();
  },
  unmounted() {
    window.removeEventListener('resize', this.getAppHeight);
  },
};
</script>

<style lang="scss">
.login {
  position: relative;
  overflow: hidden;

  @include desktop-xs {
    height: 100vh;
  }
  @include tablet-landscape {
    height: 100vh;
  }
  @include iphone-11(landscape) {
    height: auto;
  }
  @include iphone-11-pro-max(landscape) {
    height: auto;
  }
  @include iphone-12-pro-max(landscape) {
    height: auto;
  }
  @include ipad-without-home-btn(landscape) {
    height: var(--app-height);
  }
  @include ipad-without-home-btn(portrait) {
    height: var(--app-height);
  }
}

.form-view {
  display: flex;
  height: 100%;

  &__content {
    flex: 1;
    position: relative;
    height: 100%;
    width: 100%;
    color: var(--gray-1);

    @include tablet-portrait {
      display: block;
    }
    @include desktop-xs {
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
    }
    @include phone-landscape(landscape) {
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
    }
  }

  &__nest {
    width: 100%;
    padding: 0 17px;

    @include tablet-portrait {
      padding: 0 20px;
    }
    @include phone-landscape(landscape) {
      padding: 0 20px;
    }
    @include iphone-11(landscape) {
      padding: 0 20px;
    }
    @include iphone-11-pro-max(landscape) {
      padding: 0 20px;
    }
    @include iphone-12-pro-max(landscape) {
      padding: 0 20px;
    }
  }

  &__inner {
    display: flex;
    flex-flow: column;
    position: relative;
    width: 400px;
    min-height: var(--app-height);
    max-width: 100%;
    text-align: center;
    padding: 20px 0 90px 0;
    margin: 0 auto;
    justify-content: center;

    @include phone-portrait {
      padding: 30px 0 90px 0;
    }
    @include tablet-portrait(portrait) {
      justify-content: center;
    }
    @include tablet-landscape {
      justify-content: center;
    }
    @include phone-landscape(landscape) {
      padding: 20px 0 35px 0;
    }
    @include iphone-11(landscape) {
      padding: 20px 0 35px 0;
    }
    @include iphone-11-pro-max(landscape) {
      padding: 20px 0 35px 0;
    }
    @include iphone-12-pro-max(landscape) {
      padding: 20px 0 35px 0;
    }
  }

  &__top {
    position: relative;
    margin-bottom: 10px;

    @include phone-portrait {
      margin-bottom: 20px;
    }

    + .form-view__description {
      font-size: 18px;
      margin-top: 0;
    }
  }

  &__back {
    position: absolute;
    left: -14px;
    top: 50%;
    width: 42px;
    font-size: 24px;
    line-height: 42px;
    text-align: center;
    transform: translateY(-50%);
  }

  &__bottom {
    text-align: center;
    margin: 30px 0 0 0;

    @include phone-landscape {
      margin: 40px 0 0 0;
    }

    a {
      color: var(--gray-1);

      &:active {
        color: var(--white);
      }

      @include desktop-xs {
        &:hover {
          color: var(--white);
        }
      }
    }
  }

  &__title {
    font-size: 26px;
    line-height: 45px;
    font-weight: $semiBold;
    color: var(--white);
    margin: 0;

    @include phone-portrait {
      font-size: 30px;
      line-height: 55px;
    }
    @include tablet-portrait {
      line-height: 61px;
    }
    @include tablet-landscape {
      line-height: 1.3;
    }

    &--es,
    &--de {
      @include phone-portrait {
        line-height: 35px;
        padding: 10px 0;
      }
    }
  }

  &__description {
    font-size: $text-md;
    color: var(--gray-3);
    margin: 10px 0;

    .shrink-space {
      letter-spacing: -1px;
      @include tablet-portrait {
        letter-spacing: unset;
      }
    }

    @include phone-portrait {
      font-size: $text-lg;
      margin: 20px 0;
    }
    @include tablet-portrait {
      margin: 20px 0 30px 0;
    }
    @include iphone-11(landscape) {
      margin: 20px 0;
    }
    @include iphone-11-pro-max(landscape) {
      margin: 20px 0;
    }
    @include iphone-12-pro-max(landscape) {
      margin: 20px 0;
    }

    &--fr,
    &--pt {
      br.hide-mobile {
        display: none;
      }
    }

    br {
      display: none;

      @include phone-portrait {
        display: block;
      }
    }

    + .form-view__bottom {
      margin-top: 10px;
    }
  }

  &__error-info {
    display: block;
    text-align: left;
    color: var(--gray-3);
    margin: 6px 0 4px 0;

    a {
      color: var(--gray-1);

      @include desktop-xs {
        &:hover {
          color: var(--white);
        }
      }

      &:active {
        color: var(--white);
      }
    }
  }

  &__form {
    display: flex;
    flex-flow: column;
  }

  &__fieldset {
    text-align: left;
    margin-bottom: 12px;

    @include tablet-portrait {
      margin-bottom: 10px;
    }
  }

  &__input-nest {
    display: flex;
    flex-flow: column;
    position: relative;
    border: 1px solid var(--gray-7);
    border-radius: 5px;
    background: var(--gray-7);

    &--password {
      flex-flow: row;

      + .form-view__error-info {
        margin: 12px 0 0 0;
      }
    }

    &--error {
      border-color: var(--red);
    }

    &--textarea {
      padding: 30px 0 10px 0;
    }
  }

  &__input,
  &__textarea {
    display: block;
    width: 100%;
    height: 50px;
    font-size: $text-lg;
    color: var(--gray-1);
    padding: 20px 17px 0 17px;
    border: none;
    outline: none;
    background: none;
    // Prevent FF input autofill color
    filter: none;

    @include phone-portrait {
      height: 58px;
    }

    // lastpass input background icon position
    &[type='email'] {
      background-position-x: 96% !important;
    }

    &--error {
      &:focus ~ .form-view__placeholder,
      &:not(:placeholder-shown) ~ .form-view__placeholder {
        color: var(--red);
      }
    }

    &:focus ~ .form-view__placeholder,
    &:not(:placeholder-shown) ~ .form-view__placeholder {
      font-size: 14px;
      margin-top: -12px;
    }
    &:-webkit-autofill {
      ~ .form-view__placeholder {
        transition: none;
      }
    }
  }

  &__textarea {
    height: 154px;
    padding-top: 0px;
    resize: none;
    line-height: 1.2;

    @include phone-portrait {
      height: 204px;
    }
    @include tablet-portrait {
      height: 254px;
    }

    + .form-view__placeholder {
      top: 20px;
      transform: none;
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 17px;
    color: var(--gray-3);
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    user-select: none;
    cursor: text;
  }

  &__field-icon {
    &--slash {
      margin-right: -1px;
    }
  }

  &__password-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    color: var(--gray-3);
    transition: all 0.2s ease-out;
    overflow: hidden;
    cursor: pointer;

    &:active {
      color: var(--gray-1);
    }

    @include phone-portrait {
      width: 60px;
    }
    @include desktop-xs {
      &:hover {
        color: var(--gray-1);
      }
    }
  }

  &__submit {
    display: block;
    width: 100%;
    line-height: 44px;
    text-align: center;
    font-weight: 600;
    color: var(--black);
    padding: 0;
    margin: 16px auto 0 auto;
    outline: none;
    border: none;
    border-radius: 5px;
    background: var(--white);
    background-position: 50% 50%;
    background-size: 100% 150%;
    transition: background-position 0.2s ease-in-out;
    cursor: pointer;

    @include phone-portrait {
      line-height: 52px;
    }

    &:hover {
      background-position: 50% 0%;
    }

    &--disabled {
      opacity: 0.6;
      cursor: default;
    }
  }

  &__submit-loading {
    display: block;
    height: 28px;
    width: 28px;
    margin: 12px auto;
    border: 2px solid transparent;
    border-top-color: var(--white);
    border-radius: 50%;
    pointer-events: none;
    animation: loading 0.6s infinite linear;
  }

  &__message {
    padding: 10px;
    margin: 0 0 20px 0;
    border: 1px solid var(--red);
    border-radius: 6px;
    background: rgba(var(--red-rgb), 0.2);
  }

  &__message-link {
    text-decoration: underline;
    cursor: pointer;
  }

  &__errors {
    font-size: $text-lg;
    text-align: left;
    padding: 0 0 0 2px;
    margin: 0 0 20px 0;

    @include tablet-portrait {
      padding: 0;
    }
  }

  &__errors-msg {
    color: var(--red);
    margin: 12px 0;
  }

  &__errors-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      color: var(--gray-3);
      padding: 0 0 0 20px;
      margin: 0 0 5px 0;

      &:last-child {
        margin: 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: 4px;
        top: 7px;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: var(--red);
      }
    }
  }

  // Chrome disable autofill white background
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:-webkit-autofill:valid {
    -webkit-transition-property: background-color, color;
    -webkit-transition-delay: 99999s !important;
  }
  // Chrome page load font fix
  input:-webkit-autofill::first-line {
    font-family: -apple-system, BlinkMacSystemFont, 'Avenir Next', Avenir, 'Helvetica Neue', Helvetica, Ubuntu, Roboto,
      Noto, 'Segoe UI', Arial, sans-serif;
    font-size: $text-lg;
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.hide-mobile {
  display: none;
  @include phone-landscape {
    display: block;
  }
}

.slide-left-enter-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-right-leave-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.slide-left-leave-active,
.slide-right-leave-active {
  transition: all 0.2s ease-in-out;

  @include tablet-landscape(landscape) {
    transition: all 0.4s ease-in-out;
  }
}
.slide-left-enter-active,
.slide-right-enter-active {
  transition: all 0.2s ease-in-out;

  @include tablet-landscape(landscape) {
    transition: all 0.4s ease-in-out 0.2s;
  }
}
.slide-left-enter-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-right-leave-active {
  opacity: 0;

  @include phone-landscape {
    position: absolute;
  }
}
.slide-left-enter-active {
  transform: translateX(5%);
}
.slide-right-enter-active {
  transform: translateX(-5%);
}
.slide-left-leave-active {
  transform: translateX(-5%);
}
.slide-right-leave-active {
  transform: translateX(5%);
}
.slide-left-enter-to,
.slide-right-enter-to {
  opacity: 1;
  transform: translateX(0%);

  @include phone-landscape {
    position: relative;
    top: auto;
  }
}

.fade-enter-active,
.fade-leave-active {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;

  @include tablet-portrait {
    transition: opacity 0.4s ease-in-out;
  }

  .form-view__content,
  .new-customer {
    transition: opacity 0.2s ease-in-out;

    @include tablet-portrait {
      transition: opacity 0.4s ease-in-out;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  .form-view__content,
  .new-customer {
    opacity: 0;
  }
}
.fade-enter-to {
  .form-view__content,
  .new-customer {
    opacity: 1;
  }
}

.scale-enter-active {
  transition: opacity 0.2s ease-in-out;
}
.scale-enter-from,
.scale-leave-active {
  opacity: 0;

  .popup__inner {
    opacity: 0;
    transform: scale(0.8);
    transition: 0.4s ease-in;
    transition-property: transform, opacity;
  }
}
.scale-leave-active {
  transition: opacity 0.2s ease-in-out;

  .popup__inner {
    transition: 0.2s ease-out;
  }
}

// lastpass icon position
#__lpform_username,
#__lpform_username_numspan {
  transform: translateX(-14px);
}
</style>
