<template>
  <aside class="sidebar">
    <img
      :srcset="`
        ${vaporAsset(`images/login/${domain}_login_image_${backgroundRandom}@3x.jpg 3x`)},
        ${vaporAsset(`images/login/${domain}_login_image_${backgroundRandom}@2x.jpg 2x`)},
        ${vaporAsset(`images/login/${domain}_login_image_${backgroundRandom}.jpg 1x`)}
      `"
      :src="vaporAsset(`images/login/${domain}_login_image_${backgroundRandom}.jpg`)"
      class="sidebar__img" />
  </aside>
</template>

<script>
export default {
  name: 'SidebarLogin',
  props: {
    backgroundRandom: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: none;
  height: 100%;
  width: 30%;
  background: var(--gray-8);

  @include tablet-landscape {
    display: block;
  }
  @include tablet-landscape(landscape) {
    width: 35%;
  }
  @include desktop-md {
    width: 30%;
  }
  @include ipad-without-home-btn(portrait) {
    display: none;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
