<template>
  <div class="form-view">
    <div class="form-view__content">
      <div class="form-view__nest">
        <div class="form-view__inner">
          <div class="form-view__top">
            <a class="form-view__back" @click="$router.push({ name: stepPrevious || 'login' })">
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </a>

            <h1 class="form-view__title" :class="{ 'contact__title--gift': isGift }">
              {{ $t('contact.contactUs') }} <template v-if="isGift" v-html="{{ $t('auth.clickResetLink') }}"></template>
            </h1>
          </div>

          <p class="form-view__description" v-html="preventWidows(description)"></p>

          <div class="form-view__form">
            <fieldset class="form-view__fieldset contact__fieldset">
              <div class="form-view__input-nest" :class="{ 'form-view__input-nest--error': errors.firstName }">
                <input
                  v-model.trim="form.firstName"
                  type="text"
                  name="first-name"
                  placeholder=" "
                  id="first-name"
                  class="form-view__input"
                  :class="{ 'form-view__input--error': errors.firstName }"
                  @input="delete errors.firstName" />
                <label
                  for="first-name"
                  class="form-view__placeholder"
                  :class="{ 'form-view__placeholder--error': errors.firstName }">
                  <template v-if="errors.firstName">{{ errors.firstName[0] }}</template>
                  <template v-else>{{ $t('forms.yourFirstName') }}</template>
                </label>
              </div>
            </fieldset>

            <fieldset class="form-view__fieldset contact__fieldset">
              <div class="form-view__input-nest" :class="{ 'form-view__input-nest--error': errors.email }">
                <input
                  v-model.trim="form.email"
                  type="email"
                  name="email"
                  placeholder=" "
                  id="email"
                  class="form-view__input"
                  :class="{ 'form-view__input--error': errors.email }"
                  @input="delete errors.email" />
                <label
                  for="email"
                  class="form-view__placeholder"
                  :class="{ 'form-view__placeholder--error': errors.email }">
                  <template v-if="errors.email">{{ errors.email[0] }}</template>
                  <template v-else>{{ $t('forms.yourEmail') }}</template>
                </label>
              </div>
            </fieldset>

            <fieldset class="form-view__fieldset">
              <div
                class="form-view__input-nest form-view__input-nest--textarea"
                :class="{ 'form-view__input-nest--error': errors.message }">
                <textarea
                  v-model.trim="form.message"
                  name="message"
                  id="message"
                  placeholder=" "
                  class="form-view__textarea"
                  :class="{ 'form-view__input--error': errors.message }"
                  @input="delete errors.message"></textarea>
                <label
                  for="message"
                  class="form-view__placeholder"
                  :class="{ 'form-view__placeholder--error': errors.message }">
                  <template v-if="errors.message">{{ errors.message[0] }}</template>
                  <template v-else
                    >{{ $t('contact.howCanWeHelp')
                    }}<template v-if="isGift"> {{ $t('auth.pleaseIncludeGiftCode') }}</template></template
                  >
                </label>
              </div>
            </fieldset>

            <button
              class="form-view__submit contact__submit"
              :class="{ 'form-view__submit--disabled': !canSubmit }"
              @click="submitForm">
              <span class="form-view__submit-loading" v-if="submitLoading"></span>
              <template v-else>{{ $t('contact.getInTouch') }}</template>
            </button>
          </div>
        </div>
      </div>

      <language-switcher :floating="true" />
    </div>

    <sidebar :backgroundRandom="sidebarRightImg"></sidebar>
  </div>
</template>

<script>
import Sidebar from '@login/components/layout/Sidebar.vue';
import Logo from '@login/components/layout/Logo.vue';
import LanguageSwitcher from '@shared/components/ui/LanguageSwitcher.vue';
import loginMixin from '@login/mixins/loginMixin';

export default {
  name: 'ContactForm',
  components: {
    Sidebar,
    Logo,
    LanguageSwitcher,
  },
  props: {
    sidebarRightImg: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stepPrevious: 'login',
      submitLoading: false,
    };
  },
  computed: {
    canSubmit() {
      return this.form.firstName.length && this.form.message.length && this.validEmail;
    },
    isGift() {
      return this.$route.query.gift;
    },
    supportBeaconUrl() {
      return this.domain === 'pp' ? 'photographypro' : 'iphonephotographyschool';
    },
    description() {
      return this.isGift ? this.$t('auth.pleaseSendMessageWithGiftCode') : this.$t('auth.pleaseLeaveAMessage');
    },
  },
  mixins: [loginMixin],
  emits: ['showPopup'],
  methods: {
    submitForm() {
      this.validateForm();

      if (!this.noErrors || this.submitLoading) {
        return;
      }

      const form = new FormData();

      form.append('name', this.form.firstName);
      form.append('email', this.form.email);
      form.append('question', this.form.message);
      form.append('product', window.location.href);
      form.append('browser_info', window.navigator.userAgent);

      this.trackEvent('button_clicked', { button: 'contact_us_view_send_request' });

      this.submitLoading = true;

      this.$http
        .post(`https://secure.${this.supportBeaconUrl}.com/api/support-beacon/cp/login`, form)
        .then(() => {
          this.submitLoading = false;
          this.$router.push({ name: 'login' });
          this.form.message = '';
          this.$emit('showPopup');
        })
        .catch(({ response }) => {
          this.submitLoading = false;
          this.$root.errors = response.data.errors ? response.data.errors : {};
        });
    },
    validateForm() {
      this.validateFirstName();
      this.validateEmail();
      this.validateMessage();
    },
  },
  // Go to previous view when back button clicked
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.stepPrevious = from.name;
    });
  },
  unmounted() {
    this.clearErrors();
  },
};
</script>

<style lang="scss" scoped>
.contact {
  &__title {
    &--gift {
      font-size: 20px;
      line-height: 1.2;

      @include phone-portrait {
        font-size: 26px;
      }
    }
  }

  &__fieldset {
    margin-bottom: 12px;
  }

  &__submit {
    width: 100%;
    margin-top: 8px;
  }
}
</style>
