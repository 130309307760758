<template>
  <div class="form-view login-form">
    <div class="form-view__content">
      <div class="form-view__nest">
        <div class="form-view__inner login-form__inner" :class="isFromBlog ? 'login-form__inner--new-user' : ''">
          <div class="form-view__top">
            <router-link :to="{ name: 'login-check-email' }" class="form-view__back">
              <font-awesome-icon :icon="['fa', 'chevron-left']" />
            </router-link>
            <logo></logo>
          </div>

          <p class="register__description">
            {{ $t('auth.useYourIPS') }} iPhone Photography School
            <br class="hide-mobile" />
            {{ $t('auth.orPP') }}
          </p>
          <div class="form-view__form">
            <fieldset class="form-view__fieldset">
              <div class="form-view__input-nest" :class="{ 'form-view__input-nest--error': errors.email }">
                <input
                  v-model.trim="form.email"
                  type="email"
                  name="email"
                  placeholder=" "
                  id="username"
                  class="form-view__input"
                  :class="{ 'form-view__input--error': errors.email }"
                  @input="delete errors.email"
                  @keyup.enter="submitForm" />
                <label
                  for="username"
                  class="form-view__placeholder"
                  :class="{ 'form-view__placeholder--error': errors.email }">
                  <template v-if="errors.email">{{ errors.email[0] }}</template>
                  <template v-else>{{ $t('forms.yourEmail') }}</template>
                </label>
              </div>
            </fieldset>
            <fieldset class="form-view__fieldset">
              <div
                class="form-view__input-nest form-view__input-nest--password"
                :class="{ 'form-view__input-nest--error': errors.password }">
                <input
                  v-model.trim="form.password"
                  :type="passwordVisible ? 'text' : 'password'"
                  name="password"
                  placeholder=" "
                  id="password"
                  class="form-view__input form-view__input--password"
                  :class="{ 'form-view__input--error': errors.password }"
                  @input="delete errors.password"
                  @keyup.enter="submitForm"
                  autocapitalize="off" />
                <div class="form-view__password-icon" title="Show Password" @click.stop="togglePasswordVisible">
                  <font-awesome-icon :icon="['fas', 'eye-slash']" v-if="passwordVisible" />
                  <font-awesome-icon :icon="['fas', 'eye']" v-else />
                </div>
                <label
                  for="password"
                  class="form-view__placeholder form-view__placeholder--error"
                  v-if="errors.password">
                  {{ errors.password[0] }}
                </label>
                <label for="password" class="form-view__placeholder" v-else>{{ $t('forms.yourPassword') }}</label>
              </div>

              <span class="form-view__error-info" v-if="errors.password && canSubmit">
                {{ $t('auth.pleaseTryAgainOr') }}
                <router-link :to="{ name: 'forgot-password' }">{{ $t('auth.clickToReset') }}</router-link
                >.
              </span>
              <router-link :to="{ name: 'forgot-password' }" class="login-form__forgot" v-else>{{
                $t('auth.forgotPassword')
              }}</router-link>
            </fieldset>

            <button
              class="form-view__submit"
              :class="{ 'form-view__submit--disabled': !canSubmit }"
              @click="submitForm">
              <span class="form-view__submit-loading" v-if="submitLoading"></span>
              <template v-else>{{ $t('logIn') }}</template>
            </button>
          </div>

          <div class="form-view__bottom">
            <router-link :to="{ name: 'contact' }">{{ $t('forms.needHelp') }}</router-link>
          </div>
        </div>
      </div>

      <language-switcher :floating="true" />
    </div>

    <sidebar :backgroundRandom="sidebarLeftImg"></sidebar>
  </div>
</template>

<script>
import Sidebar from '@login/components/layout/Sidebar.vue';
import Logo from '@login/components/layout/Logo.vue';
import LanguageSwitcher from '@shared/components/ui/LanguageSwitcher.vue';
import loginMixin from '@login/mixins/loginMixin';

export default {
  name: 'LoginForm',
  components: {
    Sidebar,
    Logo,
    LanguageSwitcher,
  },
  props: {
    sidebarLeftImg: {
      type: Number,
      required: true,
    },
    customerPopupClosed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      submitLoading: false,
      passwordVisible: false,
    };
  },
  computed: {
    isFromBlog() {
      return this.$route.query.from === 'blog';
    },
    canSubmit() {
      return this.form.email.length && this.form.password.length && this.validEmail;
    },
  },
  mixins: [loginMixin],
  methods: {
    submitForm() {
      this.validateForm();

      if (!this.noErrors || this.submitLoading) {
        return;
      }

      const form = new FormData();

      form.append('email', this.form.email);
      form.append('password', this.form.password);

      this.submitLoading = true;

      this.$http
        .post('/login', form)
        .then(() => {
          this.trackEvent('signed_in_successfully');
          window.location = this.$route.query.redirect ? this.$route.query.redirect : '/';
        })
        .catch(({ response }) => {
          this.submitLoading = false;
          this.$root.errors = response.data.errors ? response.data.errors : {};
        });
    },
    validateForm() {
      this.validateEmail();
      this.validatePassword();
    },
    togglePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
  unmounted() {
    this.clearErrors();
  },
  beforeRouteEnter(to, from, next) {
    if (['login-check-email', 'contact'].includes(from.name)) {
      next();
    } else {
      window.location = '/login';
    }
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  @include phone-landscape(landscape) {
    display: block;
  }
  @include tablet-portrait {
    display: flex;
  }
  @include iphone-11(landscape) {
    display: block;
  }
  @include iphone-11-pro-max(landscape) {
    display: block;
  }
  @include iphone-12-pro-max(landscape) {
    display: block;
  }

  &__inner {
    transition: padding 0.2s ease-in-out;

    @include phone-landscape(landscape) {
      display: block;
      height: auto;
    }
    @include tablet-landscape {
      display: flex;
    }
    @include ipad-without-home-btn(landscape) {
      padding: 20px 0 35px 0;
    }
  }

  &__forgot {
    display: table;
    color: var(--gray-1);
    margin: 12px 0 0 auto;
    user-select: none;

    @include desktop-xs {
      &:hover {
        color: var(--white);
      }
    }

    &:active {
      color: var(--white);
    }
  }
  .form-view__submit-loading {
    border-top-color: var(--black);
  }
}

.form-view {
  &__back {
    top: 0;
  }
  &__bottom {
    &-email,
    &-link {
      color: var(--gray-3);
    }
    &-link {
      font-style: italic;
    }
    &-wrong-user {
      color: var(--gray-3);
      margin-bottom: 40px;
    }
  }
}
</style>
