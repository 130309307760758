<template>
  <div class="recaptcha">
    <div class="recaptcha__inner">
      <p class="recaptcha__description" v-html="$t('auth.recaptcha')"></p>
      <vue-recaptcha
        ref="vueRecaptcha"
        class="recaptcha__nest"
        sitekey="6LeteckUAAAAABCkOBnT_9H1MWnYzgADYbFHuTsn"
        size="normal"
        theme="dark"
        :show="1"
        @verify="verified">
      </vue-recaptcha>
    </div>
  </div>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';

export default {
  name: 'RecaptchaLogin',
  components: {
    vueRecaptcha,
  },
  emits: ['recaptchaSuccess'],
  methods: {
    verified(response) {
      setTimeout(() => this.$emit('recaptchaSuccess', response), 600);
      setTimeout(() => this.$refs.vueRecaptcha.reset(), 1200);
    },
  },
};
</script>

<style lang="scss" scoped>
.recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(14px);
  z-index: 20;

  @supports not (backdrop-filter: blur(15px)) {
    background: rgba(0, 0, 0, 0.9);
  }

  @include tablet-landscape(landscape) {
    width: 65%;
  }
  @include desktop-md {
    width: 70%;
  }

  &__inner {
    text-align: center;
  }

  &__description {
    font-size: $text-lg;
    font-weight: $semiBold;
    color: var(--white);
    margin: 0 0 30px 0;
  }

  &__nest {
    min-height: 78px;
  }
}
</style>
